@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
  margin: 0;
  font-family: -apple-system, "Manrope", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-poppins: Poppins;
  --font-urbanist: Urbanist;
  --font-manrope: Manrope;

  /* font sizes */
  --font-size-6xl-6: 1.6rem;
  --font-size-xl: 1.25rem;
  --font-size-5xl-7: 1.544rem;

  /* Colors */
  --color-darkslateblue-100: #7d61ad;
  --color-darkslateblue-200: #573a87;
  --base-white: #fff;
  --color-gray-100: #18181b;
  --color-gray-200: rgba(0, 0, 0, 0.1);

  /* Gaps */
  --gap-base: 1rem;
  --gap-3xl: 1.375rem;
  --gap-xl: 1.25rem;

  /* Paddings */
  --padding-xl: 1.25rem;
  --padding-8xl: 1.687rem;
  --padding-5xl: 1.5rem;
  --padding-base: 1rem;

  /* Border radiuses */
  --br-xl: 20px;
  --br-13xl-1: 32.1px;
  --br-6xs-2: 6.2px;
  --br-27xl-6: 46.6px;
  --br-56xl-7: 75.7px;
}
