.auth-container {
  display: flex;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;

  .left-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    max-height: 100%;

    @media (max-width: 992px) {
      display: none;
    }
  }
  .auth-section {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;

    .auth-form {
      margin: 5rem;
      max-width: 400px;
      display: flex;
      flex-direction: column;

      .company.selected {
        border: 3px solid #573a87;
      }

      .companies {
        padding: 3px;
      }
      .company {
        border: 3px solid #fff;
      }

      .brand-info {
        img {
          width: 80%;
          margin-bottom: 20px;
        }

        .form-heading {
          font-size: 3rem;
          text-transform: none;
          font-weight: 700;
        }

        .form-subheading {
          font-size: 1.752rem;
          text-transform: none;
          margin-bottom: 20px;
          color: #666;
          text-align: left;
        }
      }

      .input-label {
        font-size: 1.52rem;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      .input-field {
        padding: 10px;
        font-size: 1.52rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
      }

      .otp-input-wrapper {
        display: flex;
        align-items: center;

        .otp-visibility-toggle {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .otp-resend {
        text-align: right;
        margin-bottom: 20px;
        font-size: 1.52rem;

        .text-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .submit-button {
        background-color: #573a87;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 5px;
        font-size: 1.52rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }

      .terms-agreement {
        font-size: 12px;
        color: #666;
        margin-top: 20px;

        .text-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
