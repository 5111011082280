.business-company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  height: 100%;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;
}

.business-company-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  .placeholder-font {
    padding: 8px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

.form-container {
  // max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.paragraph-el {
  text-align: center;
  margin-bottom: 2rem;
  color: #555;
}

form {
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    text-align: start;

    label {
      flex: 1;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      input,
      select {
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  }

  .business-button {
    padding: 10px 20px;
    font-size: 1.5rem;
    color: #fff;
    background-color: #573a87;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.label-font {
  margin-bottom: 5px;
  font-weight: 700;
}
