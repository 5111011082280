.main {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
}
.Greattr_SzIcon {
  width: 400px;

  position: relative;
  // max-height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.SuccessComponent {
  width: 400px;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
.Account-created {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Account-createdContainer {
  max-width: 60rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
}
.your-account-is,
.your-safety-check {
  font-size: 24px;
  align-self: center;
  position: relative;
  line-height: 3.105rem;
}
.your-account-is {
  font-size: var(--font-size-6xl-6);
  line-height: 2.404rem;
}
.safetycontainer,
.safetytext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.safetycontainer {
  margin-top: 2rem;
  align-items: center;
  gap: 2.006rem;
  max-width: 100%;
}
.are-you-interested-container,
.have-you-tried {
  align-self: center;

  font-size: 18px;
}
.are-you-interested-container {
  font-size: var(--font-size-6xl-6);
  line-height: 2.404rem;
  color: var(--color-gray-100);
  font-family: var(--font-urbanist);
}
.bnpltext {
  align-self: center;

  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.label {
  position: relative;
  font-size: var(--font-size-5xl-7);
  line-height: 2.375rem;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--base-white);
  text-align: center;
  display: inline-block;
  min-width: 2.75rem;
}
.secondary-button {
  cursor: pointer;
  border: 0;
  padding: 0.562rem var(--padding-xl) 0.568rem;
  background-color: var(--color-darkslateblue-200);
  flex: 1;
  border-radius: 6.17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 10.5rem;
}
.esignbutton:hover,
.secondary-button:hover {
  background-color: var(--color-darkslateblue-100);
}
.label1 {
  position: relative;
  line-height: 2.375rem;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  min-width: 2.125rem;
}
.secondary-button1 {
  flex: 1;
  border-radius: 6.17px;
  background-color: var(--base-white);
  border: 1.5px solid #bbbfc1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.437rem var(--padding-xl);
  min-width: 10.5rem;
}
.button-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.768rem;
  text-align: center;
  font-size: var(--font-size-5xl-7);
  color: #242731;
  font-family: var(--font-poppins);
}
.bnplcontainer,
.right-data,
.safety-data {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.bnplcontainer {
  align-items: flex-start;
  gap: 1.562rem;
  font-size: 2.006rem;
  color: #000;
  font-family: var(--font-manrope);
}
.right-data,
.safety-data {
  align-items: center;
  max-width: 100%;
}
.safety-data {
  gap: 5.312rem;
  flex-shrink: 0;
}
.right-data {
  padding: 0.7rem 0 0;
  box-sizing: border-box;
}
.finalscreen-button {
  position: relative;
  font-size: var(--font-size-6xl-6);
  line-height: 2.438rem;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--base-white);
  text-align: left;
}
.esignbutton {
  cursor: pointer;
  border: 0;
  padding: 1.2rem 1.2rem 1.206rem;
  background-color: var(--color-darkslateblue-200);
  align-self: stretch;
  border-radius: 8.01px;
  overflow: hidden;
  display: flex;
  // justify-self: center;
  // align-self: center;
  min-width: 400px;
  max-width: 600px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btnContainer {
  display: flex;
  align-items: center;
}
.final-screen,
.right,
.right-container {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.right {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.final-screen {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #3b2463;

  padding: 1rem 3rem;
  gap: 3.5rem;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 2.406rem;
  color: var(--color-gray-100);
  font-family: var(--font-urbanist);
}

@media screen and (max-width: 450px) {
  .final-screen {
    width: auto;
    height: auto;
    position: relative;
    background-color: #3b2463;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 1rem 1.5rem;
    gap: 3.5rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 2.406rem;
    color: var(--color-gray-100);
    font-family: var(--font-urbanist);
  }

  .greattrlogo-icon {
    width: 32.188rem;
    height: 100px;
    position: relative;
    // max-height: 100%;
    overflow: hidden;
    max-width: 100%;
  }

  .your-safety-check {
    line-height: 2.105rem;
  }
}

@media screen and (min-width: 450px) {
  .final-screen {
    width: auto;
    height: auto;
    position: relative;
    background-color: #3b2463;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 1rem 1.5rem;
    gap: 3.5rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 2.406rem;
    color: var(--color-gray-100);
    font-family: var(--font-urbanist);
  }

  .greattrlogo-icon {
    width: 32.188rem;
    height: 100px;
    position: relative;
    // max-height: 100%;
    overflow: hidden;
    max-width: 100%;
  }

  .your-safety-check {
    line-height: 2.105rem;
  }
}

@media screen and (min-width: 800px) {
  .main {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    height: 100%;

    max-width: 100%;
  }

  .greattrlogo-icon {
    // width: 400px;
    height: 200px;
    position: relative;
    // max-height: 100%;
    overflow: hidden;
    max-width: 100%;
  }

  .final-screen {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #3b2463;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 1rem 3rem;
    gap: 3.5rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 2.406rem;
    color: var(--color-gray-100);
    font-family: var(--font-urbanist);
  }
}
