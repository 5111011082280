.greattrlogo {
  width: 200px;
  align-self: center;
  align-items: center;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
}

.personal_company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  height: max-content;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;
  .label-font {
    margin-bottom: 5px;
    font-weight: 700;
    white-space: break-spaces;
  }
  @media (max-width: 425px) {
    .label-font {
      margin-bottom: 5px;
      font-size: 0.8rem;
      font-weight: 700;
      white-space: break-spaces;
    }
  }
  .personal_company-info-container {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 45%;
    @media (max-width: 992px) {
      width: 95%;
      max-width: none;
    }
    // width: 95%;
    text-align: center;
    font-family: "Manrope", sans-serif !important;

    .h3 {
      margin-top: 10px;
    }

    .sub-heading {
      margin-bottom: 10px;
    }

    form .error {
      color: red;
      font-size: 1rem;
      margin-top: 0rem;
    }

    @media (max-width: 425px) {
      form .error {
        font-size: 0.8rem;
      }
    }

    .form-group1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .indi-form-group {
        display: flex;
        flex-direction: column;
        width: 48%;
        text-align: start;

        .placeholder-font {
          padding: 8px;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        @media (max-width: 425px) {
          .placeholder-font {
            padding: 8px;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
        }
      }
    }

    .placeholder-font {
      padding: 8px;
      margin-bottom: 1rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .address {
      text-align: left;
      margin-bottom: 15px;
      width: 100%;

      .label-font {
        margin-bottom: 5px;
      }

      .placeholder-font {
        width: 100%;
        padding: 8px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .last-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .indi-form-group {
        display: flex;
        flex-direction: column;
        width: 30%;
        text-align: start;

        .label-font {
          margin-bottom: 5px;
        }

        .placeholder-font {
          padding: 8px;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }

    button {
      padding: 10px 20px;
      font-size: 1rem;
      color: #fff;
      background-color: #573a87;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.datePicker {
  width: auto !important;
  padding: 1rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  outline: none;
  min-width: 10rem !important;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #573a87;
  }
}
.date-dropdowns {
  display: flex;

  font-size: 1.5rem;

  // border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 425px) {
  .error {
    font-size: 0.8rem;
  }
}
//   &::placeholder {
//     color: #999;
//   }
// }
// /* Custom styles for the date picker popup */
// .react-datepicker {
//   font-size: 1.2rem !important;
//   width: 100% !important;
//   border: 1px solid #ccc;
//   border-radius: 0.5rem;
//   box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
// }

// .react-datepicker__header {
//   width: 100% !important;
//   font-size: 1.25rem !important;
//   border-bottom: 1px solid #e9ecef;
// }

// // .react-datepicker__current-month {
// //   font-weight: bold;
// //   margin-bottom: 0.8rem;
// // }
// .react-datepicker__day-names {
//   display: flex;
//   justify-content: space-between;
//   margin-left: 3%;
// }
// .react-datepicker__day {
//   width: 4rem !important;
//   //   line-height: 3.2rem;
//   font-size: 1.25rem !important;
//   //   background-color: red;
//   //   margin: 0.2rem;
//   border-radius: 0.4rem;
//   transition: background-color 0.2s ease, color 0.2s ease;

//   &:hover {
//     background-color: #e9ecef;
//   }
// }

// .react-datepicker__day--selected {
//   background-color: #573a87;
//   color: #fff;
// }

// // .react-datepicker__day--keyboard-selected {
// //   background-color: #573A87;
// //   color: #fff;
// // }

// .react-datepicker__day--today {
//   font-weight: bold !important;
//   background-color: gainsboro;
// }

// // .react-datepicker__day--disabled {
// //   color: #ccc;
// //   cursor: not-allowed;
// // }
// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   width: 100% !important;
// }
