// SCSS Styles
.cardslassi {
  margin-top: 4%;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
  font-family: "Arial", sans-serif;
  text-align: center;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0rem;
    // padding: 1rem;
  }
}
.cardslassi2 {
  margin-top: 2rem;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 1rem;
  font-family: "Arial", sans-serif;
  text-align: center;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0rem;
    flex-direction: column;
    // padding: 1rem;
  }
}
