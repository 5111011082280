.greattrlogo {
  width: 200px;
  align-self: center;
  align-items: center;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
}

.personal_company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  height: max-content;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;
  .Operator-label-font {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .first-name-label {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;

    span:first-child {
      margin-right: 0.3em;
    }

    span:last-child {
      font-size: 0.9em;
      color: #666;
    }
  }
  .personal_company-info-container {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 45%;
    @media (max-width: 992px) {
      width: 95%;
      max-width: none;
    }
    // width: 95%;
    text-align: center;
    font-family: "Manrope", sans-serif !important;

    .h3 {
      margin-top: 10px;
    }

    .sub-heading {
      margin-bottom: 10px;
    }

    .form-group1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .indi-form-group {
        display: flex;
        flex-direction: column;
        width: 48%;
        text-align: start;

        .Dist-pd-placeholder-font {
          padding: 8px;
          font-size: 1.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }

    .placeholder-font {
      padding: 8px;
      font-size: 1.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .address {
      margin-bottom: 15px;
      width: 100%;

      .label-font {
        margin-bottom: 5px;
      }

      .placeholder-font {
        width: 100%;
        padding: 8px;
        font-size: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .last-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .indi-form-group {
        display: flex;
        flex-direction: column;
        width: 30%;
        text-align: start;

        .label-font {
          margin-bottom: 5px;
        }

        .placeholder-font {
          padding: 8px;
          font-size: 1.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }

    button {
      padding: 10px 20px;
      font-size: 1.5rem;
      color: #fff;
      background-color: #573a87;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.datePicker {
  width: auto !important;
  padding: 1rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  outline: none;
  min-width: 10rem !important;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #573a87;
  }
}
.date-dropdowns {
  display: flex;
  font-size: 2rem;
  // padding-left: 1.5rem;
  // border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 768px) {
    // Styles for larger screens (tablets and up)
    gap: 10px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    // border: 1px solid #ccc; // Optional border for larger screens
  }

  @media (max-width: 767px) {
    gap: 5px; // Reduced gap for smaller screens
    padding: 6px;
    // Consider removing or adjusting border for smaller screens
  }
}
