.logo {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.logoText {
  color: #573a87;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 2rem;
  /* background-color: #f2f2f2; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.subformHeading {
  margin-bottom: 15px;

  font-size: 2rem;

  font-weight: 700;
  color: #242731;
  text-align: center;
}
.selectOption {
  margin-bottom: 8px;
  max-width: 600;
}
.instantLoan {
  font-weight: 700;
  font-size: 2rem;

  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.ImageContainer {
  display: flex;
  flex-direction: row;
}
.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.SurveyproceedBtn {
  max-width: 600px;
}

.proceedBtn {
  margin-top: 15px;
  background-color: #573a87;
  max-width: 400px;
  color: #ffffff;
  width: 90%;
  border-radius: 3px;
  padding: 14px;
}
.qrText {
  text-align: center;
  font-size: 2.2rem;

  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;

  margin-left: 50px;
  margin-right: 50px;
}

.divider {
  width: 100%;
  border: none;
  background-color: #bbbfc1;
  border-top: 0.2px solid #bbbfc1;
}

/* Labels */
.formContainer label {
  font-size: 2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;

  font-weight: bold;
  color: #242426;
  margin-bottom: 8px;
}

.selectOption {
  margin-bottom: 20px;
}

.selectOption label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.button {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid #bbbfc1;
  border-radius: 4px;
  width: 150px;
  background-color: #fff;
  color: #000000;
  max-width: 100%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #573a87;
  color: #fff;
}

.button.selected {
  background-color: #573a87;
  color: #fff;
}
.selectLanguageContainer {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
}
/* Input Fields */
.formContainer input[type="text"] {
  width: 100%;
  padding: 15px 20px;

  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputContainer {
  width: 100%;
  max-width: 600;
  display: flex;
  flex-direction: column;

  padding-left: 10;
  padding-right: 10;
  margin: 10;
}
.SurveyInput {
  /* align-self: center; */
  display: block;
  max-width: 600px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.choiceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.selectLanguage {
  margin-top: 10px;
  margin-bottom: 15px;
  color: #242426;
  font-weight: 500;
  font-size: 14px;
}

.formContainer input[type="text"]:focus {
  outline: none;
  border-color: #573a87; /* Change the border color when focused */
  box-shadow: 0 0 5px rgba(87, 58, 135, 0.5); /* Add a subtle box-shadow effect when focused */
}

/* company Select CSS */

.company-selector {
  text-align: center;
  padding: 5;
  height: 50%;
}

.companies {
  display: flex;
  padding: 2px;

  margin-bottom: 5px;
  justify-content: center;
  gap: 15px;
}

@media screen and (max-width: 375px) {
  .companies {
    gap: 5px;
  }
}

.company {
  cursor: pointer;
  /* padding: 2px; */
  border: 5px solid #fff;
  border-radius: 50%;
  transition: border 0.3s;
}

.company img {
  width: 70px; /* Adjust based on your image size */
  height: 70px; /* Adjust based on your image size */
}

.company.selected {
  border: 5px solid #573a87;
}

/* company Select CSS */
