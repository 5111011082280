@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.greattrlogo {
  max-width: 100%;
  align-self: center;
  align-items: center;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
}

.company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  height: max-content;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;

  .sub-heading {
    color: #9c9aa5;

    font-size: 2.9rem;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .sub-heading-resend {
    color: #9c9aa5;

    font-size: 2.9rem;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .sub-heading-resend:hover {
    color: purple;
  }

  .form-group1 {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1.5rem;
  }

  .label-font {
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }
  .placeholder-font {
    padding: 10;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }

  .indi-form-group {
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .address {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
    padding: 1.5rem;
  }

  .last-row {
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1.5rem;
  }

  .company-info-container {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    // max-width: 45%;
    @media (min-width: 900px) {
      max-width: 45%;
    }
    text-align: center;

    h1 {
      font-size: 4rem;
      color: #573a87;
    }

    p {
      font-size: 1rem;
      color: #9c9aa5;
    }

    h3 {
      font-size: 2.85rem;
      color: #333;
    }

    p {
      font-size: 2rem;
      color: #666;
      font-family: "Manrope", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      margin-bottom: 2rem;
    }

    form {
      display: flex;
      flex-direction: column;
      // max-width: 500px;
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        text-align: start;
        label {
          margin-bottom: 0.5rem;
          font-size: 1rem;
          color: #333;
          font-weight: 600;
        }

        input,
        select {
          padding: 0.75rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        input::placeholder {
          color: #979797;
        }
      }

      button {
        padding: 0.75rem;
        font-size: 1rem;
        color: #fff;
        background-color: #573a87;
        border: none;
        border-radius: 5px;
        font-family: "Manrope", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #4a37c9;
        }
      }
    }
  }
}

.continue-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #573a87;
  border: none;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a37c9;
  }
}

.otpstyle {
  width: inherit;
  height: 60px;
  color: #313d4f;
  text-align: center;
  font-size: 3rem;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.safety-check-form-group {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex: 1;
  height: 10%;
  padding: 10;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .safety-check-form-group {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    flex: 1;
    height: 10%;
    padding: 10;
    width: 100%;
  }
}
