.logo-1-icon {
  width: 32.188rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.left-content1 {
  width: 35.131rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  max-width: 100%;
}
.asset-icon {
  width: 35.125rem;
  position: relative;
  max-height: 100%;
  object-fit: contain;
  max-width: 100%;
}
.rectangle-copy-37 {
  height: 1.313rem;
  width: 1.313rem;
  position: relative;
  border-radius: 75.73px;
  background-color: var(--color-darkslateblue-200);
}
.rectangle-copy-36,
.rectangle-copy-38,
.rectangle-copy-39,
.rectangle-copy-40 {
  height: 1.313rem;
  width: 1.313rem;
  position: relative;
  border-radius: 75.73px;
  background-color: var(--color-gray-200);
}
.bullets,
.bullets-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.bullets {
  width: 15.875rem;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.bullets-wrapper {
  width: 35.131rem;
  justify-content: center;
  max-width: 100%;
}
.left-content {
  align-self: stretch;
  border-radius: 25.61px;
  background-color: var(--base-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-1xl);
  max-width: 100%;

  .greattrlogo-icon {
    width: 600;
    height: 600;
  }
}

@media screen and (min-width: 1200px) {
  .left-content {
    height: 100%;
    width: 100%;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .greattrlogo-icon {
    width: 800;
    height: 800;
  }
}

@media screen and (max-width: 450px) {
  .left-content {
    border-radius: 0 0 25.61px 25.61px;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;

    .greattrlogo-icon {
      width: 400;
      height: 400;
    }

    .mySwiper .swiper-pagination-bullet {
      background-color: gray; /* Change to your desired color */
      width: 5px; /* Increase the width */
      height: 5px; /* Increase the height */
    }

    .mySwiper .swiper-pagination-bullet-active {
      background-color: #573a87; /* Change to your desired active color */
      width: 5px; /* Increase the width of the active bullet */
      height: 4px; /* Increase the height of the active bullet */
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper .swiper-pagination-bullet {
  background-color: gray; /* Change to your desired color */
  width: 10px; /* Increase the width */
  height: 10px; /* Increase the height */
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: #573a87; /* Change to your desired active color */
  width: 15px; /* Increase the width of the active bullet */
  height: 15px; /* Increase the height of the active bullet */
}
