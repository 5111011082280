@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap");

.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  .left-side {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .right-side {
    // background: #fff;
    display: flex;
    width: 100%;
    // max-width: 400px;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    .login-form {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      align-self: center;

      .api-hub-info {
        // text-align: center;

        img {
          width: 80%;
          margin-bottom: 20px;
        }

        .form-title {
          font-size: 3rem;
          // margin-bottom: 10px;
          text-transform: none;
          font-weight: 700;
        }

        .form-description {
          font-size: 1.752rem;
          text-transform: none;
          margin-bottom: 20px;
          color: #666;
        }
      }

      .form-label {
        font-size: 1.52rem;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      .form-input {
        padding: 10px;
        font-size: 1.52rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
      }

      .password-input-container {
        display: flex;
        align-items: center;

        .OTP-toggle {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .OTP-forgot {
        text-align: right;
        margin-bottom: 20px;
        font-size: 1.52rem;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .form-button {
        background-color: #573a87;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        font-size: 1.52rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }

      .not-registered {
        font-size: 12px;
        color: #666;
        margin-top: 20px;
        // text-align: center;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.merchant-button {
  margin-bottom: 10;
  background-color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  transition: box-shadow 0.3s ease-in-out; /* Optional: Add transition for smooth effect */

  &.selected {
    border-color: #573a87;

    .icon {
      color: #573a87;
    }
  }
}

.operator-button {
  margin-bottom: 10;
  background-color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  transition: box-shadow 0.3s ease-in-out; /* Optional: Add transition for smooth effect */

  &.selected {
    border-color: #573a87;

    .icon {
      color: #573a87;
    }
  }
}

.icon {
  transition: color 0.3s;
}

.button-title-label {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.button-description-label {
  color: #8692a6;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
