@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap");

.login-container {
  padding-top: 60;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  .left-side {
    margin-left: 20;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .right-side {
    display: flex;
    width: 100%;
    width: 80%;
    align-items: center;
    align-content: center;
    justify-content: center;
    .login-form {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      align-self: center;

      .api-hub-info {
        // text-align: center;

        img {
          width: 80%;
          margin-bottom: 20px;
        }

        .form-title {
          font-size: 3rem;
          // margin-bottom: 10px;
          text-transform: none;
          font-weight: 700;
        }

        .form-description {
          font-size: 1.752rem;
          text-transform: none;
          margin-bottom: 20px;
          color: #666;
        }
      }

      .form-label {
        font-size: 1.52rem;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      .form-input {
        padding: 10px;
        font-size: 1.52rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
      }

      .password-input-container {
        display: flex;
        align-items: center;

        .OTP-toggle {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .OTP-forgot {
        text-align: right;
        margin-bottom: 20px;
        font-size: 1.52rem;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .form-button {
        background-color: #573a87;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        font-size: 1.52rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }

      .not-registered {
        font-size: 12px;
        color: #666;
        margin-top: 20px;
        // text-align: center;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.merchant-button {
  margin-bottom: 10;
  background-color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  transition: box-shadow 0.3s ease-in-out; /* Optional: Add transition for smooth effect */

  &.selected {
    border-color: #573a87;

    .icon {
      color: #573a87;
    }
  }
}

.operator-button {
  margin-bottom: 10;
  background-color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  transition: box-shadow 0.3s ease-in-out; /* Optional: Add transition for smooth effect */

  &.selected {
    border-color: #573a87;

    .icon {
      color: #573a87;
    }
  }
}

.icon {
  transition: color 0.3s;
}

.button-title-label {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.button-description-label {
  color: #8692a6;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

/* GreattrAd.css */
.greattr-ad {
  width: 40vw;
  height: 90vh;
  font-family: "Inter", sans-serif;

  background: linear-gradient(30deg, #573a87, #ab9dc3);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  font-family: "Manrope", sans-serif;
  color: #ffffff;
}

.ad-header {
  margin-bottom: 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #5a2d82;
}

.ad-title {
  font-size: 20px;
  font-weight: bold;
  color: #5a2d82;
}

.ad-subtitle {
  font-size: 14px;
  color: #ffffff;
}

.ad-content {
  margin-bottom: 20px;
}

.ad-image {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  margin: 0 auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-flexible {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.ad-description {
  font-size: 14px;
  color: #ffffff;
}

.ad-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ad-partner-text {
  max-width: 400px;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 20px;
}

.ad-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.ad-button {
  width: 80px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.ad-button.yes {
  background-color: #fff;
  width: 150px;
  color: #5a2d82;
  border: 2px solid white;
}

.ad-button.no {
  background-color: transparent;
  width: 150px;
  color: #fff;
  border: 2px solid white;
}
