@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  height: max-content;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;

  .sub-heading {
    color: #9c9aa5;
    font-size: 2.9rem;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .form-group1 {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1.5rem;
  }

  .label-font {
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }
  .placeholder-font {
    padding: 10;
    width: 50%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }

  @media screen and (max-width: 400px) {
    .placeholder-font {
      width: 100%;
      padding: 10;
      font-size: 1rem;
    }
  }

  .indi-form-group {
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .address {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
    padding: 1.5rem;
  }

  .last-row {
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1.5rem;
  }

  .company-info-container {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 0.4rem;
    border-radius: 10px;
    // max-width: 45%;
    @media (min-width: 900px) {
      max-width: 45%;
    }
    text-align: center;

    h1 {
      font-size: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: #573a87;
    }

    p {
      font-size: 1rem;
      color: #9c9aa5;
    }

    h3 {
      font-size: 2.85rem;
      color: #333;
    }

    p {
      font-size: 1.2rem;
      color: #666;
      font-family: "Manrope", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      margin-bottom: 2rem;
    }

    form {
      display: flex;
      flex-direction: column;
      // max-width: 500px;
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        text-align: start;
        label {
          margin-bottom: 0.5rem;
          font-size: 1rem;
          color: #333;
          font-weight: 600;
        }

        input,
        select {
          padding: 0.75rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        input::placeholder {
          color: #979797;
        }
      }

      button {
        padding: 0.75rem;
        font-size: 1rem;
        color: #fff;
        background-color: #573a87;
        border: none;
        border-radius: 5px;
        font-family: "Manrope", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #4a37c9;
        }
      }
    }
  }
}

.continue-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #573a87;
  border: none;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a37c9;
  }
}

.otpstyle {
  width: inherit;
  height: 60px;
  color: #313d4f;
  text-align: center;
  font-size: 2rem;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.company-info .company-info-container .company-info-logo-container {
  display: flex;
  justify-content: center;
}
