@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

// Breakpoints
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;

// Mixins for responsive design
@mixin for-phone-only {
  @media (max-width: #{$breakpoint-small - 1}) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}

.uploaddocs_company-info {
  display: flex;
  background-color: #f1f4ff;
  min-height: 100vh;
  width: 100%;
  font-family: "Manrope", sans-serif !important;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @include for-phone-only {
    padding: 20px;
  }

  .label-font {
    font-family: "Manrope", sans-serif !important;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;

    @include for-tablet-portrait-up {
      font-size: 15px;
    }
  }

  .uploaddocs_company-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-family: "Manrope", sans-serif !important;

    @include for-tablet-landscape-up {
      max-width: 80%;
    }

    @include for-desktop-up {
      max-width: 60%;
    }

    .card {
      box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;
      background-color: #fff;
      border: none;
      width: 100%;
    }

    .card-body {
      padding: 20px;

      @include for-tablet-portrait-up {
        padding: 40px;
      }
    }

    .btn-primary {
      margin-top: 20;
      border-color: #573a87 !important;
      background-color: #573a87 !important;
      color: #fff;

      width: 100%;

      @include for-tablet-portrait-up {
        width: auto;
      }
    }
    .DistSafetyCheck-btns-submit-button {
      width: 100%;
    }

    .form-submit {
      padding: 10px 20px;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 400;

      @include for-tablet-portrait-up {
        padding: 13px 30px;
        font-size: 15px;
      }
    }

    .kb-data-box {
      width: 100%;
      flex: 1;
    }

    .kb-modal-data-title {
      margin-bottom: 10px;
    }

    .kb-data-title h6 {
      margin-top: 10;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;

      @include for-tablet-portrait-up {
        font-size: 15px;
      }
    }

    .kb-file-upload {
      display: flex;
      width: 100%;
    }

    .file-upload-box {
      border: 1px dashed #b6bed1;
      background-color: #f0f2f7;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8194aa;
      font-weight: 400;
      font-size: 14px;
      width: 100%;

      @include for-tablet-portrait-up {
        font-size: 15px;
      }

      .file-upload-input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .file-link,
    .file-link2 {
      color: #475f7b;
      text-decoration: underline;
      margin-left: 3px;
    }

    .file-link2 {
      font-size: 1.2rem;
      text-decoration: none;

      @include for-tablet-portrait-up {
        font-size: 1.35rem;
      }
    }

    .file-upload-box .file-link:hover {
      text-decoration: none;
    }

    .file-atc-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      @include for-tablet-portrait-up {
        flex-direction: row;
        align-items: center;
      }
    }

    .file-image {
      width: 100%;
      height: 120px;
      background-size: cover;
      border-radius: 5px;
      margin-bottom: 10px;
      background-color: #eaecf1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #475f7b;
      padding: 3px;

      @include for-tablet-portrait-up {
        width: 130px;
        height: 85px;
        margin-right: 15px;
        margin-bottom: 0;
        font-size: 30px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
    }

    .file-detail {
      flex: 1;
      width: 100%;

      @include for-tablet-portrait-up {
        width: calc(100% - 145px);
      }

      h6 {
        word-break: break-all;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 12px;
        color: #8194aa;
        line-height: initial;
        font-weight: 400;
        margin-bottom: 8px;
      }
    }

    .file-actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      @include for-tablet-portrait-up {
        justify-content: flex-end;
      }
    }

    .file-action-btn {
      font-size: 12px;
      color: #ff0000 !important;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 0;
      padding: 0 !important;
      background-color: transparent !important;
      border: none;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        color: #3d546f;
        text-decoration: underline;
      }
    }

    .file-atc-box:last-child {
      margin-bottom: 0;
    }
  }
}

// Assuming you have already defined the global styles and mixins as per your previous SCSS

.dc-file-upload-section {
  margin-bottom: 20px;

  .dc_label {
    text-align: left;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
    font-family: "Manrope", sans-serif !important;

    @include for-phone-only() {
      font-size: 0.9rem;
    }
  }

  .kb-file-upload {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .file-upload-box {
      border: 1px dashed #b6bed1;
      background-color: #f0f2f7;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8194aa;
      font-weight: 400;
      font-size: 14px;
      width: 100%;

      .file-upload-input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }

      .file-link2 {
        font-size: 1rem;
        text-decoration: none;
        margin-left: 5px;
        font-family: "Manrope", sans-serif !important;

        &:hover {
          text-decoration: underline;
        }
      }

      .file-link {
        color: #475f7b;
        text-decoration: underline;
        font-family: "Manrope", sans-serif !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .dc-file-preview {
    margin-top: 10px;
    border: 1px solid #b6bed1;
    border-radius: 4px;
    padding: 15px;
    font-family: "Manrope", sans-serif !important;

    .dc-file-detail {
      h6 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        font-size: 12px;
        color: #8194aa;
        line-height: initial;
        margin-bottom: 0;

        span {
          margin-right: 10px;
        }
      }

      .dc-file-actions {
        margin-top: 8px;

        .dc-file-action-btn {
          font-size: 12px;
          color: #ff0000 !important;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 0;
          padding: 0 !important;
          background-color: transparent !important;
          border: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: #3d546f;
          }
        }
      }
    }
  }
}

.UploadDocumentInstructionImg {
  width: 100%;
  margin-top: 10;
  margin-bottom: 10;

  @include for-tablet-landscape-up {
    width: 40%;
  }
}

.layout {
  padding-top: 5%;
  padding-inline: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header5 {
  margin-top: 2;
  margin-bottom: 1rem;
  font-size: 1rem;
  padding-inline: 20%;

  @include for-phone-only {
    padding-inline: 0;
  }
}
