.logo-container {
  width: 32.188rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.content-wrapper {
  width: 35.131rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  max-width: 100%;
}

.image-asset {
  width: 35.125rem;
  position: relative;
  max-height: 100%;
  object-fit: contain;
  max-width: 100%;
}

.bullet-active {
  height: 1.313rem;
  width: 1.313rem;
  position: relative;
  border-radius: 75.73px;
  background-color: var(--color-darkslateblue-200);
}

.bullet-inactive {
  height: 1.313rem;
  width: 1.313rem;
  position: relative;
  border-radius: 75.73px;
  background-color: var(--color-gray-200);
}

.bullet-list,
.bullet-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.bullet-list {
  width: 15.875rem;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.bullet-container {
  width: 35.131rem;
  justify-content: center;
  max-width: 100%;
}

.login-content {
  margin-left: 100px;
  height: 100%;
  max-height: 90%;

  align-self: stretch;
  border-radius: 25.61px;
  background-color: var(--base-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20;
  box-sizing: border-box;
  gap: var(--gap-1xl);
}

.logo-image {
  padding-bottom: var(--padding-xl);
  width: 400;
  height: 400;
}

@media screen and (min-width: 1200px) {
  .logo-image {
    padding-bottom: var(--padding-xl);
    width: 600;
    height: 600;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fa0404;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 60;
}

.mySwiper .swiper-pagination-bullet {
  background-color: gray;
  width: 5px;
  height: 5px;
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: #573a87;
  width: 7px;
  height: 7px;
}
