$primary: #082431;
$secondary: green;
$dark: #000;
$light: #fff;
$success: #0fa;
$info: #555;
$danger: red;
$warning: yellow;
body {
  font-family: "Manrope" !important;
  // text-transform: capitalize;
}
// @import "~bootstrap/scss/bootstrap.scss";
// CSS styles
.bg-lightx {
  background-color: #082431;
}
.sidebar .nav-link {
  color: #ffffff;
}

.sidebar .nav-link:hover {
  background-color: white;
  color: #082431;
}
.navbg {
  background-color: #082431 !important;
}
.offcanvas-header button.close {
  color: red;
}
.custom-dropdown {
  color: white !important;
}
.uploadnavlink {
  background-color: white !important  ;
  // padding: 20 !important;
  color: #082431 !important;
  border-radius: 10px;
}
.search-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 5px;

  input[type="text"] {
    border: none;
    outline: none;
    flex-grow: 1; /* Take up all available space */
    font-size: 16px;
    color: #333;

    &::placeholder {
      color: #999;
    }
  }
}
.sidebar2 {
  width: 0;
  height: 100vh;
  transition: 0.5s;
  width: 15%;
  border-right: 1px solid rgb(232, 232, 232);
}

.dropdown-header {
  color: white;
  cursor: pointer;
  display: block;
  padding: 11px 11px;
  font-size: medium;
  font-weight: 1rem;
  color: white;
  background-color: #082431;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.dropdown-options {
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #082431;
  display: flex;
  flex-direction: column;
}

.dropdown-option {
  padding: 10px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-option:hover {
  background-color: #794caa;
}

.custom-dropdown.open .dropdown-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-dropdown.open .dropdown-options {
  display: flex;
  flex-direction: column;
}
.iconx_image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconx_image-wrapper {
  display: flex;
  border-bottom: 1px solid rgb(209, 209, 209);
  padding: 10px;
  border-radius: 8px;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-start; /* Adjust the margin as needed */
}
.iconx_image-wrapperselected {
  border-bottom: 6px solid #082431;
  font-weight: bold;
  color: #082431;
}

.iconx {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
}
.iconxselected {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
}
.iconx_image-label {
  display: block;
  font-size: 1rem;
  margin-top: 5px; /* Adjust the margin as needed */
  text-align: left;
  margin-left: 5px;
}
.iconx_image-labelselected {
  display: block;
  font-size: 1rem;
  margin-top: 5px; /* Adjust the margin as needed */
  text-align: left;
  margin-left: 5px;
}
.input-group-text {
  padding: 5px !important;
}
.input-group {
  padding: 0px !important;
}
// p {
//   margin: 0px !important;
//   padding: 0px !important;
// }
.form-control {
  padding: 0px !important;
}
@keyframes jumpSpin {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.15, 0.75);
  }
  20% {
    transform-origin: center;
    transform: scale(0.9, 1.2) translateY(-150px) rotateZ(365deg);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(0px) rotateZ(360deg);
  }
  35% {
    transform: scale(1.1, 0.8) translateY(50px) rotateZ(360deg);
  }
  40% {
    transform: translateY(0px) rotateZ(360deg);
  }
  65% {
    transform: skew(-5deg, 0deg) translateY(0px) rotateZ(360deg);
  }
  70% {
    transform: skew(5deg, 0deg) translateY(0px) rotateZ(360deg);
  }
  75% {
    transform: translateY(0px) rotateZ(360deg);
  }
  100% {
    transform: scale(1, 1) rotateZ(360deg);
  }
}

.lovin-it {
  width: 60px; /* Adding width for demonstration */
  position: absolute; /* You may need to adjust positioning based on your layout */
  top: 50%; /* Move the top edge of the element to the center of its container */
  left: 50%; /* Move the left edge of the element to the center of its container */
  transform: translate(
    -50%,
    -50%
  ); /* Offset the element by 50% of its own width and height */
  z-index: 10;
  transform-origin: bottom;
  animation: jumpSpin 2.5s infinite ease-in-out;
}
.custButton {
  display: flex;
  align-items: center;
  max-width: 180px;
  height: auto;
  cursor: pointer;
  margin: 0.5rem;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px; // Adjust as needed
  justify-content: space-between;
  position: relative;

  .custButton-text {
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .custButton-icon {
    margin: 0;
    padding: 0;
    transition: transform 0.3s ease;
  }

  .rotate-icon {
    transform: rotate(45deg);
  }
}

.customx-input-group {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin: 0.5rem;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-radius: 4px; // Rounded corners

  .customx-input-group-text {
    background-color: #f0f0f0; // Light gray background
    border: 1px solid #ccc; // Light border
    border-radius: 4px; // Rounded corners
    padding: 0; // Adjust as needed
    margin-right: 8px; // Spacing between icon and input
  }

  .customx-form-control {
    flex: 1; // Fill remaining space
    border: 0px solid transparent !important; // Light border
    outline: none !important;
    padding: 0; // Adjust as needed
    border-radius: 4px; // Rounded corners
    font-size: 14px; // Adjust font size
  }

  .customx-img {
    width: 30px; // Adjust image width as needed
    height: auto; // Maintain aspect ratio
    margin-right: 8px; // Spacing between image and input
    padding: 2%;
  }

  .customx-react-select {
    flex: 1; // Fill remaining space
    margin-left: 8px; // Spacing between input and select
  }
}
.FilterDropDown-menu {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 200px; // Adjust as needed
  max-height: 200px; // Adjust as needed
  overflow-y: auto;
}

.FilterDropDown-item-text {
  padding: 10px;
  display: block;
  padding: 5px 10px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ddd;
  }
}
.modalx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* ensure modal is on top of other content */

  .modalx-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* shadow for depth */
    max-width: 400px;
    width: 100%;

    .h2x {
      font-size: 1rem;
      margin-bottom: 15px;
    }

    .progressx {
      width: 100%;
      height: 20px;
      margin-top: 10px;
    }
  }
}
.custButton2 {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px; // Adjust as needed
  justify-content: center;
}
.lovin-it2 {
  align-self: center;
  width: 60px; /* Adding width for demonstration */
  position: relative; /* You may need to adjust positioning based on your layout */
  margin: 5%;
  transform-origin: bottom;
  // animation: jumpSpin 2.5s infinite ease-in-out;
}
progress {
  margin-top: 10px;
  width: 100%; /* Make the progress bar fill its container horizontally */
  height: 20px; /* Set the height of the progress bar */
  appearance: none; /* Remove default styles */
}

progress::-webkit-progress-bar {
  background-color: #f0f0f0; /* Set background color of the progress bar */
}

progress::-webkit-progress-value {
  background-color: #ffe375; /* Set color of the progress bar */
}
