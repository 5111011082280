.main {
  width: 51.131rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  // padding: 0 0 0.65rem;
  box-sizing: border-box;
  min-width: 51.131rem;
  max-width: 100%;
}
.greattrlogo-icon {
  width: 32.188rem;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.your-account-is,
.your-safety-check {
  align-self: stretch;
  position: relative;
  line-height: 3.105rem;
}
.your-account-is {
  font-size: var(--font-size-6xl-6);
  line-height: 2.404rem;
}
.safetycontainer,
.safetytext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.safetycontainer {
  align-items: center;
  gap: 2.006rem;
  max-width: 100%;
}
.are-you-interested-container,
.have-you-tried {
  align-self: stretch;
  position: relative;
}
.are-you-interested-container {
  font-size: var(--font-size-6xl-6);
  line-height: 2.404rem;
  color: var(--color-gray-100);
  font-family: var(--font-urbanist);
}
.bnpltext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.label {
  position: relative;
  font-size: var(--font-size-5xl-7);
  line-height: 2.375rem;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--base-white);
  text-align: center;
  display: inline-block;
  min-width: 2.75rem;
}
.secondary-button {
  cursor: pointer;
  border: 0;
  padding: 0.562rem var(--padding-xl) 0.568rem;
  background-color: var(--color-darkslateblue-200);
  flex: 1;
  border-radius: 6.17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 10.5rem;
}
.esignbutton:hover,
.secondary-button:hover {
  background-color: var(--color-darkslateblue-100);
}
.label1 {
  position: relative;
  line-height: 2.375rem;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  min-width: 2.125rem;
}
.secondary-button1 {
  flex: 1;
  border-radius: 6.17px;
  background-color: var(--base-white);
  border: 1.5px solid #bbbfc1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.437rem var(--padding-xl);
  min-width: 10.5rem;
}
.button-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.768rem;
  text-align: center;
  font-size: var(--font-size-5xl-7);
  color: #242731;
  font-family: var(--font-poppins);
}
.bnplcontainer,
.right-data,
.safety-data {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.bnplcontainer {
  align-items: flex-start;
  gap: 1.562rem;
  font-size: 2.006rem;
  color: #000;
  font-family: var(--font-manrope);
}
.right-data,
.safety-data {
  align-items: center;
  max-width: 100%;
}
.safety-data {
  gap: 1.312rem;
  flex-shrink: 0;
}
.right-data {
  padding: 0.7rem 0 0;
  box-sizing: border-box;
}
.finalscreen-button {
  position: relative;
  font-size: var(--font-size-6xl-6);
  line-height: 2.438rem;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--base-white);
  text-align: left;
}
.esignbutton {
  cursor: pointer;
  border: 0;
  padding: 1.2rem var(--padding-xl) 1.206rem;
  background-color: var(--color-darkslateblue-200);
  align-self: stretch;
  border-radius: 8.01px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.GoTOHome {
  cursor: pointer;
  border: 0;
  padding: 1.2rem var(--padding-xl) 1.206rem;
  background-color: var(--color-darkslateblue-200);
  border-radius: 8.01px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}
.final-screen,
.right,
.right-container {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.right-container {
  flex: 1;
  border-radius: 32.05px 32.05px 0 0;
  background-color: var(--base-white);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.187rem 4.906rem;
  gap: 2.25rem;
  max-width: 100%;
}
.final-screen,
.right {
  flex-direction: row;
}
.right {
  flex: 1 1;
  border-radius: var(--br-xl) var(--br-xl);
  background-color: var(--base-white);
  align-items: center;
  justify-content: center;
  padding: 3.937rem 9.187rem;
  min-width: 36.375rem;
  max-width: 100%;
  flex-shrink: 0;
}
.final-screen {
  width: 100%;
  position: relative;
  background-color: #3b2463;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem 3rem;
  gap: 3.5rem;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 2.406rem;
  color: var(--color-gray-100);
  font-family: var(--font-urbanist);
}

.right-container {
  padding-bottom: 1.188rem;
  box-sizing: border-box;
}
.right {
  padding: 1.563rem 1.563rem;
  box-sizing: border-box;
}
@media screen and (max-width: 1425px) {
  .main {
    flex: 1;
  }
  .final-screen {
    flex-wrap: wrap;

    box-sizing: border-box;
  }
  .right-container {
    padding-bottom: 1.188rem;
    box-sizing: border-box;
  }
  .right {
    padding: 1.563rem 1.563rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1200px) {
  .main {
    min-width: 100%;
  }
  .right-container {
    padding-bottom: 1.188rem;
    box-sizing: border-box;
  }
  .right {
    padding: 1.563rem 1.563rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .your-safety-check {
    font-size: 1.938rem;
    line-height: 2.5rem;
  }
  .safetycontainer {
    gap: var(--gap-base);
  }
  .have-you-tried {
    font-size: 1.625rem;
  }
  .button-container {
    flex-wrap: wrap;
  }
  .safety-data {
    gap: 2.625rem;
  }
  .right-container {
    gap: 1.125rem;
  }
  .right {
    padding-top: var(--padding-8xl);
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .final-screen {
    padding: 0.5rem;
    padding-top: 0;
  }

  .right-container {
    padding-bottom: 1.188rem;
    box-sizing: border-box;
    border-radius: 32.05px 32.05px 0 0;
    padding: 0 0.187rem 0.506rem;
  }

  .right {
    box-sizing: border-box;
  }

  .your-safety-check {
    font-size: 1.438rem;
    line-height: 1.875rem;
  }
  .your-account-is {
    font-size: var(--font-size-xl);
    line-height: 1.938rem;
  }
  .have-you-tried {
    font-size: 1.188rem;
  }
  .are-you-interested-container,
  .label,
  .label1 {
    font-size: var(--font-size-xl);
    line-height: 1.938rem;
  }
  .label,
  .label1 {
    line-height: 1.875rem;
  }
  .safety-data {
    gap: 1.313rem;
  }
  .finalscreen-button {
    align-self: center;
    font-size: var(--font-size-xl);
    line-height: 1.938rem;
  }
  .right-container {
    padding-bottom: 2.063rem;
    box-sizing: border-box;
  }
  .right {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .final-screen {
    gap: 1.75rem;
  }
}
