.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  .left-side {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .right-side {
    // background: #fff;
    display: flex;
    width: 100%;
    // max-width: 400px;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    .login-form {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      align-self: center;

      @media screen and (max-width: 375px) {
        padding: 0 2rem;
      }

      .api-hub-info {
        // text-align: center;

        img {
          width: 80%;
          margin-bottom: 20px;
        }

        .form-title {
          font-size: 3rem;
          // margin-bottom: 10px;
          text-transform: none;
          font-weight: 700;
        }

        .form-description {
          font-size: 1.752rem;
          text-transform: none;
          margin-bottom: 20px;
          color: #666;
          text-align: left;
        }
      }

      .form-label {
        font-size: 1.52rem;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      .safety-check-form-label {
        margin-top: 5px;
      }

      .form-input {
        padding: 10px;
        font-size: 1.52rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
      }

      .password-input-container {
        display: flex;
        align-items: center;

        .OTP-toggle {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .OTP-forgot {
        text-align: right;
        margin-bottom: 20px;
        font-size: 1.52rem;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .safety-check-form-link {
          margin-top: 10px;
          font-size: 1rem;
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .form-button {
        background-color: #573a87;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 5px;
        font-size: 1.52rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }

      .safety-check-form-button {
        background-color: #573a87;
        color: #fff;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        // font-size: 1.52rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }

      .not-registered {
        font-size: 12px;
        color: #666;
        margin-top: 20px;
        // text-align: center;

        .form-link {
          color: #573a87;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
