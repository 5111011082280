.greattrlogo {
  width: 300px;
  align-self: center;
  align-items: center;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
}

.Dist-business-company-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 2rem;
  max-width: 950px;
  border-radius: 10px;
  text-align: center;
  .placeholder-font {
    padding: 8px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

.Dist-business-company-info {
  display: flex;
  min-height: 100vh;
  background-color: #f1f4ff;
  width: 100%;
  height: max-content;
  font-family: "Manrope", sans-serif !important;
  align-items: flex-start; // Changed from center to flex-start
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  &-container {
    flex-direction: column;
    justify-content: flex-start; // Changed from center to flex-start
    background-color: white;
    padding: 1.5rem;
    border-radius: 10px;
    width: 100%;
    // max-width: 800px;
    text-align: center;
    margin-top: 2rem; // Reduced from 200px to 2rem for mobile

    .paragraph-el {
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }

    h3 {
      font-size: 3rem;
      margin-bottom: 0.75rem;
    }

    h4 {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    h5 {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
  }
}

form {
  display: flex;
  flex-direction: column;

  .Dist-form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    text-align: start;

    label {
      font-size: 1.5rem;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .label-font {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: 700;
    font-size: 1.2rem;
  }

  input,
  select {
    width: 100%;
    padding: 0.6rem;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Manrope", sans-serif;
  }

  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }
}

.Dist-business-company-info
  .Dist-business-company-info-container
  .Dist-business-button {
  padding: 1.5rem 1.5rem;
  font-size: 1.5rem;
  color: #fff;
  background-color: #573a87;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
  width: 100%;

  &:hover {
    background-color: #4a3173;
  }
}

// @media (min-width: 481px) {
//   .business-company-info {
//     padding: 1.5rem;

//     &-container {
//       padding: 2rem;
//       margin-top: 3rem; // Slightly increased for medium screens

//       img {
//         width: 35%;
//       }

//       h3 {
//         font-size: 1.75rem;
//       }

//       h4 {
//         font-size: 1.5rem;
//       }

//       h5 {
//         font-size: 1.2rem;
//       }
//     }
//   }

//   form {
//     .form-row {
//       flex-direction: row;
//       flex-wrap: wrap;

//       label {
//         flex: 1 1 calc(50% - 0.5rem);
//         margin-right: 0;
//         &:nth-child(2n) {
//           margin-right: 0;
//         }
//       }
//     }

//     input,
//     select {
//       font-size: 1rem;
//       padding: 0.75rem;
//     }

//     .business-button {
//       width: auto;
//       font-size: 1.25rem;
//       padding: 1rem 2rem;
//     }
//   }
// }

@media (min-width: 769px) {
  .business-company-info {
    padding: 2rem;
    align-items: center; // Center vertically on larger screens

    &-container {
      padding: 2.5rem;
      margin-top: 0; // Remove top margin on larger screens
    }
  }

  form {
    max-width: 900px;
    .form-row {
      label {
        flex: 1 1 calc(33.33% - 0.67rem);
        margin-right: 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
