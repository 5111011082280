.Dist-upload-container {
  background-color: #fafbff;
  min-height: 100vh;
  font-family: 'Manrope', sans-serif;
  padding: 1.5rem;
  font-size: 1.4rem; /* Added base font size */
}

.Dist-upload-wrapper {
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
}

.Dist-upload-logo {
  width: 180px;
  margin: 0 auto 2.5rem;
  display: block;
}

.Dist-upload-progress {
  color: #6b3fa0;
  font-size: 1.4rem; /* Updated font size */
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: center;
}

.Dist-upload-title {
  font-size: 2.5rem; /* Updated font size */
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: #1a1f36;
  text-align: center;
}

.Dist-upload-subtitle {
  color: #64748b;
  font-size: 1.4rem; /* Updated font size */
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}

.Dist-upload-info-image {
  max-width: 360px;
  height: auto;
  margin: 0 auto 2.5rem;
  display: block;
}

.Dist-upload-grid {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.Dist-upload-box {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.25rem;
  background-color: #ffffff;
  transition: all 0.2s ease;
  font-size: 1.4rem; /* Added font size */
}

.Dist-upload-box:hover {
  border-color: #6b3fa0;
  box-shadow: 0 4px 12px rgba(107, 63, 160, 0.08);
}

.Dist-upload-label {
  font-weight: 600;
  font-size: 1.4rem; /* Updated font size */
  color: #334155;
  margin-bottom: 1rem;
}

.Dist-upload-dropzone {
  border: 2px dashed #e2e8f0;
  border-radius: 10px;
  padding: 2rem 1rem;
  text-align: center;
  background-color: #fafbff;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem; /* Added font size */
}

.Dist-upload-dropzone:hover {
  border-color: #6b3fa0;
  background-color: #f8f7ff;
}

.Dist-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.Dist-upload-icon {
  color: #6b3fa0;
  margin-bottom: 0.75rem;
  font-size: 2rem; /* Updated font size */
}

.Dist-upload-text {
  color: #64748b;
  font-size: 1.4rem; /* Updated font size */
  margin-bottom: 0.25rem;
}

.Dist-upload-link {
  color: #6b3fa0;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.25rem;
  font-size: 1.4rem; /* Added font size */
}

.Dist-upload-preview {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  font-size: 1.4rem; /* Added font size */
}

.Dist-upload-file-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
  min-width: 0;
}

.Dist-upload-file-details {
  flex: 1;
  min-width: 0;
}

.Dist-upload-file-details h6 {
  margin: 0;
  font-size: 1.4rem; /* Updated font size */
  color: #334155;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Dist-upload-file-details p {
  margin: 0.125rem 0 0;
  font-size: 1.2rem; /* Updated font size */
  color: #64748b;
}

.Dist-upload-delete {
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 1.4rem; /* Added font size */
}

.Dist-upload-delete:hover {
  background-color: #fef2f2;
}

.Dist-upload-button {
  background-color: #6b3fa0;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.875rem 2rem;
  font-size: 1.4rem; /* Updated font size */
  font-weight: 600;
  min-width: 180px;
  transition: all 0.2s ease;
  margin: 1rem auto;
  display: block;
}

.Dist-upload-button:hover:not(:disabled) {
  background-color: #5c3589;
  transform: translateY(-1px);
}

.Dist-upload-button:disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
}

.Dist-upload-error {
  color: #ef4444;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  border-radius: 8px;
  padding: 0.875rem 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 1.4rem; /* Updated font size */
  font-weight: 500;
}

@media (min-width: 640px) {
  .Dist-upload-wrapper {
    padding: 2.5rem;
  }
  
  .Dist-upload-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .Dist-upload-container {
    padding: 2.5rem;
  }
  
  .Dist-upload-wrapper {
    padding: 3rem;
  }

  .Dist-upload-title {
    font-size: 3rem; /* Updated font size */
  }
  
  .Dist-upload-grid {
    gap: 2rem;
  }
}

@media (max-width: 767px) {
  .Dist-upload-info-image {
    max-width: 100%; /* Make the image take up the full width of its container */
    padding: 0 1rem; /* Add some padding to prevent the image from touching the edges */
  }
}